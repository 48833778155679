import {
  faCheckCircle,
  faExclamationCircle,
  faInfoCircle,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";

export class AlertUtils {
  constructor() {
    
    this.alertTemplates = {
      "incentive-earned-alert": {
        message:
          "You’re eligible for an incentive of ₹[incentiveAmount]! for Consumer No: [consumerNumber], Bill Date: [billDate]. Please Pay your bill on time to claim it",
        type: "incentive-earned-alert",
        alertName: "Incentive Earned",
        iconName: faCheckCircle, // Success icon
        iconColor: "text-green-500", // Green for success
        className: "bg-[#b7d8ca] bg-opacity-40",
      },
      "missed-incentive-alert": {
        message:
          "You missed an incentive of ₹[incentiveAmount] for Consumer No: [consumerNumber], Bill Date: [billDate]. Optimize usage to save next time!",
        type: "missed-incentive-alert",
        alertName: "Missed Incentive",
        iconName: faExclamationCircle, // Warning icon
        iconColor: "text-yellow-500", // Yellow for warning
        className: "bg-[#b7d8ca] bg-opacity-40",
      },
      "penalty-incurred-alert": {
        message:
          "A penalty of ₹[penaltyAmount] has been paid for Consumer No: [consumerNumber], Bill Date: [billDate].",
        type: "penalty-incurred-alert",
        alertName: "Penalty Incurred",
        iconName: faWarning, // Warning icon
        iconColor: "text-orange-500", // Red for danger
        className: "bg-[#b7d8ca] bg-opacity-40",
      },
      "repeated-late-payments-alert": {
        message:
          "Multiple late payments detected for Consumer No: [consumerNumber], Bill Date: [billDate]. Timely payments can help avoid penalties.",
        type: "repeated-late-payments-alert",
        alertName: "Repeated Late Payments",
        iconName: faInfoCircle, // Info icon
        iconColor: "text-blue-500", // Blue for info
        className: "bg-[#b7d8ca] bg-opacity-40",
      },
      "non-payment-alert": {
        message:
          "Your bill of ₹[amount] is overdue for Consumer No: [consumerNumber], Bill Date: [billDate]. Please Pay immediately to avoid disconnection or penalties.",
        type: "non-payment-alert",
        alertName: "Non-Payment",
        iconName: faInfoCircle, // Info icon
        iconColor: "text-red-500", // Blue for info
        className: "bg-[#b7d8ca] bg-opacity-40",
      },
      "low-load-factor-alert" : {
        message: "Your Load Factor (LF) for [billDate] is [LF]%, which is lower than the past three months' average [avgLF]%",
        type: "low-load-factor-alert",
        alertName: "Low Load Factor Detected!",
        iconName: faWarning, // Warning icon
        iconColor: "text-orange-500", // Red for danger
        className: "bg-[#b7d8ca] bg-opacity-40",  
      },
      "load-factor-drop-alert" : {
        message: "Your LF for [billDate] is [LF]%, which is [LF_Drop]% lower than the same month last year ([lastYearLF]%).",
        type: "load-factor-drop-alert",
        alertName: "Load Factor Drop Alert!",
        iconName: faWarning, // Warning icon
        iconColor: "text-orange-500", // Red for danger
        className: "bg-[#b7d8ca] bg-opacity-40",  
      },
      "load-factor-anomaly-alert" : {
        message: "Significant fluctuation in LF ([LF]%) observed compared to last month ([prevMonthLF]%).",
        type: "load-factor-anomaly-alert",
        alertName: "Load Factor Anomaly Detected!",
        iconName: faWarning, // Warning icon
        iconColor: "text-orange-500", // Red for danger
        className: "bg-[#b7d8ca] bg-opacity-40", 
      },
      "power-factor-penalty-alert" : {
        message: "Your Power Factor is below [avgPF], leading to a [penalty]% penalty this month.",
        type: "power-factor-penalty-alert",
        alertName: "Power Factor Penalty Alert",
        iconName: faWarning, // Warning icon
        iconColor: "text-orange-500", // Red for danger
        className: "bg-[#b7d8ca] bg-opacity-40", 
      },
      "power-factor-regulatory-alert": {
        message: "Your PF ([avgPF]) is below the regulatory minimum of [stateRequirement] for your state. Consider improving it to avoid penalties.",
        type: "power-factor-regulatory-alert",
        alertName: "Power Factor Below Regulatory Requirement Alert",
        iconName: faWarning, // Warning icon
        iconColor: "text-orange-500", // Red for danger
        className: "bg-[#b7d8ca] bg-opacity-40", 
      },
      "tod-consumption-efficiency-alert" : {
        message: "Optimize usage in [slot] for better efficiency by shifting non-essential loads to lower-cost slots!",
        type: "tod-consumption-efficiency-alert",
        alertName: "TOD/TOU Consumption Efficiency Alert",
        iconName: faWarning, // Warning icon
        iconColor: "text-orange-500", // Red for danger
        className: "bg-[#b7d8ca] bg-opacity-40", 
      },
      "potential-savings-alert": {
        message: "Adjust usage to save ₹[savingsAmount] by increasing usage in [lowCostSlot] and reduce it in [highCostSlot]!",
        type: "potential-savings-alert",
        alertName: "Potential Savings Alert",
        iconName: faWarning, // Warning icon
        iconColor: "text-orange-500", // Red for danger
        className: "bg-[#b7d8ca] bg-opacity-40", 
      }

    };
  }

  /**
   * Generates an alert with dynamic values.
   * @param {string} alertType - The type of the alert.
   * @param {Object} values - Key-value pairs to replace placeholders in the message.
   * @returns {Object} The dynamic alert object.
   */
  getAlert(alertType, values) {
    
    //console.log( "getAlert", values);

    const alertTemplate = this.alertTemplates[alertType];
    if (!alertTemplate) {
      throw new Error(`Alert type "${alertType}" not found.`);
    }

    // Replace placeholders in the message with the provided values
    let dynamicMessage = alertTemplate.message;
    Object.keys(values).forEach((key) => {
      const placeholder = `[${key}]`;
      dynamicMessage = dynamicMessage.replaceAll(placeholder, values[key]);
    });

    return {
      ...alertTemplate,
      message: dynamicMessage,
    };
  }
}
