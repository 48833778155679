import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  fetchNotificationList,
  updateNotificationToRead,
} from "../../api/apiDataExchange";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AlertUtils } from "../../utils/alertUtils";
import { getUserOrgId } from "../../utils/authenticationUtils";
import LoaderComponent from "../loaderCompent/LoaderComponent";

const NotificationPopover = ({
  isOpenNotoficationPopup,
  setisOpenNotoficationPopup,
  organisationId,
}) => {
  const alertUtil = new AlertUtils();
  const [loader, setLoader] = useState(false);
  const popoverRef = useRef(null);
  const [notificationList, setNotificationList] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [alerts, setAlerts] = useState([]); // state for alerts
  const [orgID, setOrgID] = useState(null);

  const getOrganisationId = async () => {
    const orgId = await getUserOrgId();
    setOrgID(orgId);
  };

  useEffect(() => {
    getOrganisationId();
  }, []);

  useEffect(() => {
    if (!isOpenNotoficationPopup) return;

    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setisOpenNotoficationPopup(false);
      }
    };

    // Add event listener when popover is visible
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Cleanup event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpenNotoficationPopup]);

  useEffect(() => {
    console.log("Popover Visible:", isOpenNotoficationPopup);
  }, [isOpenNotoficationPopup]);

  useEffect(() => {
    const getdata = async () => {
      const payload = {
        organisationId: orgID,
        status: "active",
      };
      if (isOpenNotoficationPopup && orgID) {
        setLoader(true);
        const response = await fetchNotificationList(payload);
        if (response.statusCode === 200) {
          console.log({ data: response.data });
          setNotificationList(response.data.results);
          setNotificationCount(response.data.total);
          setAlerts(response.data.results);
        }
        setLoader(false);
      }
    };
    getdata();
  }, [isOpenNotoficationPopup, orgID]);

  const togglePopover = () => {
    const getdata = async () => {
      const payload = {
        organisationId: orgID,
      };
      await updateNotificationToRead(payload);
    };
    getdata();
    setisOpenNotoficationPopup(!isOpenNotoficationPopup);
  };

  const popoverStyle = {
    position: "fixed",
    top: "72px",
    right: "5px",
    width: "500px",
    maxHeight: `${window.innerHeight - 30}px`,
    backgroundColor: "#fff",
    border: "1px solid #ddd",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    overflowY: "auto",
    zIndex: 1000,
    padding: "16px",
    animation: "slideIn 0.3s ease-out",
  };

  return (
    <div>
      {isOpenNotoficationPopup && (
        <div ref={popoverRef} style={{ ...popoverStyle }}>
          <div className="p-4">
            <div className="flex justify-between">
              <h4 className="text-lg font-semibold mb-2">Alerts</h4>
              <Link
                to={{
                  pathname: "/notifications",
                  state: { organisationId: organisationId },
                }}
              >
                {notificationCount > 0 && (
                  <div
                    onClick={togglePopover}
                    className="underline text-blue-700 hover:cursor-pointer"
                  >
                    View More
                  </div>
                )}
              </Link>
            </div>
            <hr className="py-2" />
            {notificationCount  >  0 && !loader ? (
              <ul className="space-y-2">
                {alerts.slice(0, 4).map((alert, index) => {
                  let billDate = new Date(
                    alert?.powerConsumptionId?.billMonthOfLastDay
                  ).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                  });

                  let incentiveAmount =
                    alert?.powerConsumptionId?.totalBillAmount?.value -
                      alert?.powerConsumptionId?.ifPaidUptoAmount?.value || 0;
                  let penaltyAmount =
                    alert?.powerConsumptionId?.ifPaidAfterAmount?.value -
                      alert?.powerConsumptionId?.totalBillAmount?.value || 0;

                  let param = {
                    consumerNumber:
                      alert?.powerConsumptionId?.meterId?.consumerNo ?? "N/A",
                    billDate: `${billDate}`,
                    incentiveAmount: incentiveAmount?.toFixed(2) ?? "0.00",
                    penaltyAmount: penaltyAmount?.toFixed(2) ?? "0.00",
                    billMonth: alert?.powerConsumptionId?.billMonth ?? "N/A",
                    amount:
                      alert?.powerConsumptionId?.totalBillAmount?.value ?? 0,
                    LF: alert?.powerConsumptionId?.LF?.value
                      ? alert.powerConsumptionId.LF.value.toFixed(2)
                      : "0.00",
                    avgPF: alert?.powerConsumptionId?.avgPF?.value
                      ? alert.powerConsumptionId.avgPF.value.toFixed(2)
                      : "0.00",
                  };

                  param = { ...param, ...alert.data };

                  // Fetch alert data using getAlert method
                  const alertData = alertUtil.getAlert(alert.type, param || {});

                  return (
                    <li
                      key={index}
                      className={`p-2 rounded-md hover:bg-gray-200 ${
                        alertData.className || ""
                      }`}
                    >
                      <div className="font-bold flex justify-between">
                        <span>
                          <FontAwesomeIcon
                            className={`w-4 h-4 mr-2 ${alertData.iconColor}`} // Apply icon color
                            icon={alertData.iconName}
                          />
                          {alertData.alertName || "Alert"}
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <span>
                          {alertData.message || "No message available"}
                        </span>
                      </div>
                    </li>
                  );
                })}
              </ul>
            ) : loader  ? (
              <div className="flex justify-center items-center mt-8">
                <LoaderComponent width={"60"} height={"60"} />
              </div>
            ) : (
              <div className="text-center">
                <p className="text-gray-600">Currently You have no alerts</p>
              </div>
            )}

            <hr className="py-2 mt-4" />
            {notificationCount > 0 && (
              <div className="mb-4">
                <span className="font-semibold">
                  Total Alerts: {notificationCount}
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationPopover;
