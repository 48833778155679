import jwtDecode from "jwt-decode";
import { getData, getOrganisationData } from "../api/apiDataExchange";


export async function getUserType() {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user?.result?.token;
  let decodedToken = "";
  decodedToken = jwtDecode(token);
  console.log({ decodedToken });
  return   decodedToken.userType[0];
}
export async function checkUserCanUploadBill() {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = user?.result?.token;
    let decodedToken = "";
    decodedToken = jwtDecode(token);
    console.log({ decodedToken });
    return   decodedToken.uploadBill;
  }

export  function checkOrganisationAssigned() {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user?.result?.token;
  let decodedToken = "";
  decodedToken = jwtDecode(token);
  console.log({ decodedToken });
  const usertype = decodedToken.userType[0];
  if (usertype !== 'admin' && !decodedToken['accessRole']) {
    return false
  } else {
    return true;
  }
}

export async function getUserOrgId() {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user?.result?.token;
  let decodedToken = "";
  decodedToken = jwtDecode(token);
  return   decodedToken.organisationId;
}
export async function getUnitsandMeters() {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user?.result?.token;
  let decodedToken = "";
  decodedToken = jwtDecode(token);
  console.log({ decodedToken });
  const organisationId = decodedToken.organisationId;
  const organisationsResponse = await getOrganisationData(
    `${process.env.REACT_APP_API_URL}/organisation/list`,
    { id: organisationId }
  );
  const organisationsList = organisationsResponse?.data?.results;
  const organisationIds = organisationsList.map((org) => org._id);
  let factoryIds;
  if (organisationIds?.length > 0) {
    const userType = decodedToken.userType[0];
    let organisationsData;
    let factoryIdQuery;
    let accessdata;
    if (userType === "admin") {
      organisationsData = await getData(
        `${process.env.REACT_APP_API_URL}/organisation/tree/${organisationId}`
      );

      return{
        userType,
        organisationsData:[organisationsData],
        organisationsList,
        organisationId,
      }
    } else {
      const { accessLevel, data } = getaccesslevel(decodedToken?.accessRole);
      console.log({ accessLevel, data });
      accessdata=data;
      let query;
      if (accessLevel === 'meter') {
        query = createQueryString({ meters: data })
      } else if (accessLevel === 'unit') {
        query = createQueryString({ units: data })
      } else if (accessLevel === 'discom') {
        query = createQueryString({ discoms: data })
      } else {
        query = createQueryString({ states: data })
      }

      console.log({ query });

      organisationsData = await getData(
        `${process.env.REACT_APP_API_URL}/organisation/tree/${organisationId}?buyerId=${localStorage.getItem("userId")}`
      );
      console.log({ organisationsData:organisationsData.data });

  

    const {units,meters,consumernos}=extractUnitsAndMeters(organisationsData);
    console.log({units,meters});
    

    return {
      userType,
      organisationsData:[organisationsData],
      organisationsList,
      organisationId,
      meters,
      units,
      discoms: accessdata,
      states: accessdata,
      accessLevel,
      consumernos

    };
  }
  }


  function extractUnitsAndMeters(organisationData) {
    const result = {
        units: [],
        meters: [],
        consumernos:[]
    };

    const factories = organisationData?.data?.factories || [];
    
    factories.forEach(factory => {
        factory.units.forEach(unit => {
            const unitData = unit._id;
            result.units.push(unitData);

            unit.meters.forEach(meter => {
                const meterData =meter._id
                const consumerNodata =meter.consumerNo
                result.meters.push(meterData);
                result.consumernos.push(consumerNodata);
            });
            
        });
    });

    return result;
}


  function createQueryString({ states = [], discoms = [], units = [], meters = [] }) {
    // Helper function to create a query parameter
    function createQueryParam(paramName, array) {
      if (array.length === 0) return '';
      return `${paramName}=${array.join(',')}`;
    }

    // Create individual query parameters
    const stateQuery = createQueryParam('filteredStates', states);
    const discomQuery = createQueryParam('filteredDiscoms', discoms);
    const unitQuery = createQueryParam('filteredUnits', units);
    const meterQuery = createQueryParam('filteredMeters', meters);

    // Combine all non-empty query parameters
    const queryParams = [stateQuery, discomQuery, unitQuery, meterQuery]
      .filter(param => param !== '')
      .join('&');

    return queryParams;
  }

  function getaccesslevel(userAccessRole) {
    if (userAccessRole.meters) {
      return { accessLevel: "meter", data: userAccessRole.meters };
    } else if (userAccessRole.units) {
      return { accessLevel: "unit", data: userAccessRole.units };
    } else if (userAccessRole.discoms) {
      return { accessLevel: "discom", data: userAccessRole.discoms };
    } else {
      return { accessLevel: "state", data: userAccessRole.states };
    }
  }
}