import React, { useState, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";
import moment from "moment/moment";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-datepicker/dist/react-datepicker.css";
import Sidebar from "../../components/sidebar/Sidebar";
import { useAuth } from "../../hooks/useAuth";
import {
  getUnitsandMeters,
  getUserOrgId,
} from "../../utils/authenticationUtils";
import LoaderComponent from "../../components/loaderCompent/LoaderComponent";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faDownload,
  faHome,
  faBackward,
} from "@fortawesome/free-solid-svg-icons";
import RecommendationNew from "./RecommendationNew";
import { ConstantUtils } from "../../utils/ConstantUtils";

const RecommendationList = () => {
  const constantUtils = new ConstantUtils();
  const { isSidebarOpen } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [stateOptions, setStateOptions] = useState([]);
  const [discomOptions, setDiscomOptions] = useState([]);
  const [consumerOptions, setConsumerOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedRecommedationYear, setSelectedRecommedationYear] =
    useState("");
  const [selectedDiscoms, setSelectedDiscoms] = useState([]);
  const [selectedConsumers, setSelectedConsumers] = useState([]);
  const [selectedMeterId, setSelectedMeterId] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [filteredData, setFilteredData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalLocation, setTotalLocation] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  const [orgID, setOrgID] = useState(null);
  const [pageSize, setPageSize] = useState(
    localStorage.getItem("pageSize") || 5
  );
  const [recommedationYears, setRecommedationYears] = useState(
    constantUtils.getLastFiveFinancialYears()
  );
  const [startDate, setStartDate] = useState(new Date("2023-04-01"));
  const [endDate, setEndDate] = useState(new Date("2024-03-31"));

  let initialPayload = {
    sortBy: "meterId",
    sortOrder: "asc",
    page: currentPage,
    pageSize: pageSize,
    organisationId: orgID,
    startDate: "04/2023",
    endDate: "03/2024",
    stateCode: "IN-MH",
  };

  const [filters, setFilters] = useState(initialPayload);
  const [initialLoad, setInitialLoad] = useState(true);
  const [search, setSearch] = useState(false);
  const [loading, setLoading] = useState(true);
  const query = new URLSearchParams(location.search);
  const [queryParamsLength, setqueryParamsLength] = useState(null);
  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState("");
  const [sortConfig, setSortConfig] = useState({
    // key: "meterId",
    // direction: "asc",
  });
  const [isClosing, setIsClosing] = useState(false);
  const [futureRecommendation, setFutureRecommendation] = useState();
  const [isFutureRecommendation, setIsFutureRecommendation] = useState(null);

  const closePopover = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      setIsOpen(false);
    }, 500);
  };

  const getOrganisationId = async () => {
    const orgId = await getUserOrgId();
    setOrgID(orgId);
  };

  useEffect(() => {
    getOrganisationId();
  }, []);
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryParamLengthvar = queryParams.size;
    setqueryParamsLength(queryParamLengthvar);
  }, [location.search]);

  const handleResize = () => {
    setHeight(window.innerHeight);
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    setIsFutureRecommendation(query.get("recommendation") === "future");
    if (query.get("recommendation") === "future") {
      const year = constantUtils.getNextFinancialYear();
      setSelectedRecommedationYear(year);
      handleStartDateEndDate(year);
      setRecommedationYears((prevYears) => {
        return [...new Set([year])];
      });
    }
  }, [location.search]);

  useEffect(() => {
    // Add event listener to update height on resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Fetch the data from API
    const getOrgDetails = async () => {
      if (orgID) {
        const { accessLevel, states, meters, units, discoms, userType } =
          await getUnitsandMeters();

        let url;

        if (userType === "admin") {
          url = `${process.env.REACT_APP_API_URL}/organisation/tree/${orgID}`;
        } else {
          url = `${
            process.env.REACT_APP_API_URL
          }/organisation/tree/${orgID}?buyerId=${localStorage.getItem(
            "userId"
          )}`;
          if (accessLevel === "meter") {
            setFilters({ ...filters, meterId: meters });
          } else if (accessLevel === "unit") {
            setFilters({ ...filters, unitId: units });
          } else if (accessLevel === "discom") {
            setFilters({ ...filters, discomCode: discoms });
          } else {
            setFilters({ ...filters, stateCode: states });
          }
        }
        fetch(url)
          .then((response) => response.json())
          .then((result) => {
            if (result.statusCode === 200) {
              setData(result.data);

              // Extract unique states
              const stateSet = new Set();
              result.data.factories.forEach((factory) => {
                factory.units.forEach((unit) => {
                  stateSet.add(
                    JSON.stringify({
                      label: unit.state.name,
                      value: unit.state.code,
                    })
                  );
                });
              });
              const parsedStates = Array.from(stateSet).map((item) =>
                JSON.parse(item)
              );
              const filteredState = parsedStates.filter(
                (state) => state.value === "IN-MH"
              );
              setStateOptions(filteredState);
            }
          });
      }
    };

    getOrgDetails();
  }, [orgID]);

  useEffect(() => {
    // Update discom options based on selected states
    if (data && selectedStates.length > 0) {
      const selectedStateCodes = selectedStates.map((state) => state.value);
      const discomSet = new Set();

      data.factories.forEach((factory) => {
        factory.units.forEach((unit) => {
          if (selectedStateCodes.includes(unit.state.code)) {
            discomSet.add(
              JSON.stringify({ label: unit.discomCode, value: unit.discomCode })
            );
          }
        });
      });

      setDiscomOptions(Array.from(discomSet).map((item) => JSON.parse(item)));
    } else {
      setDiscomOptions([]);
      setSelectedDiscoms([]); // Clear dependent selections
    }
  }, [selectedStates, data]);

  useEffect(() => {
    // Update consumer options based on selected discoms
    if (data && selectedDiscoms.length > 0) {
      const selectedDiscomCodes = selectedDiscoms.map((discom) => discom.value);
      const consumerSet = new Set();

      data.factories.forEach((factory) => {
        factory.units.forEach((unit) => {
          if (selectedDiscomCodes.includes(unit.discomCode)) {
            unit.meters.forEach((meter) => {
              consumerSet.add(
                JSON.stringify({
                  label: meter.formattedConsumerName,
                  value: meter.consumerNo,
                })
              );
            });
          }
        });
      });

      setConsumerOptions(
        Array.from(consumerSet).map((item) => JSON.parse(item))
      );
    } else {
      setConsumerOptions([]);
      setSelectedConsumers([]); // Clear dependent selections
    }
  }, [selectedDiscoms, data]);

  useEffect(() => {
    // Update location options based on selected consumers
    if (data && selectedConsumers.length > 0) {
      const selectedConsumerCodes = selectedConsumers.map(
        (consumer) => consumer.value
      );
      console.log({ selectedConsumerCodes });
      const locationSet = new Set();

      data.factories.forEach((factory) => {
        factory.units.forEach((unit) => {
          unit.meters.forEach((meter) => {
            if (selectedConsumerCodes.includes(meter.consumerNo)) {
              locationSet.add(
                JSON.stringify({
                  label: factory.address,
                  value: factory.address,
                })
              );
            }
          });
        });
      });

      console.log({ locationSet });
      setLocations(Array.from(locationSet).map((item) => JSON.parse(item)));
    } else {
      setLocations([]);
      setSelectedLocations(""); // Clear dependent selections
    }
  }, [selectedConsumers, data]);

  const getmeterIdFromConsumerNo = (consumerNo) => {
    data.factories.forEach((factory) => {
      factory.units.forEach((unit) => {
        unit.meters.forEach((meter) => {
          if (meter.consumerNo === consumerNo) {
            setSelectedMeterId(meter._id);
          }
        });
      });
    });
  };

  const updateFilterData = () => {
    if (
      selectedStates.length !== 0 ||
      selectedDiscoms.length !== 0 ||
      selectedConsumers.length !== 0 ||
      startDate !== "" ||
      endDate !== ""
    ) {
      console.log({ startDate, endDate });
      const stateCode = selectedStates.map((state) => state.value);
      const discomCode = selectedDiscoms.map((discom) => discom.value);
      const consumerNo = selectedConsumers.map((consumer) => consumer.value);
      const location = selectedLocations;
      let dateFilters = {};
      if (startDate !== "" && endDate !== "") {
        dateFilters = {
          startDate: `${String(startDate.getMonth() + 1).padStart(
            2,
            "0"
          )}/${startDate.getFullYear()}`,
          endDate: `${String(endDate.getMonth() + 1).padStart(
            2,
            "0"
          )}/${endDate.getFullYear()}`,
        };
      }
      setFilters({
        ...filters,
        ...dateFilters,
        stateCode: stateCode,
        discomCode: discomCode,
        consumerNo: consumerNo,
        location: location,
      });
      setSearch(true);
    }
  };

  useEffect(() => {
    // Filter data based on selected filters
    if (data) {
      const filtered = data.factories.map((factory) => ({
        ...factory,
        units: factory.units.filter((unit) => {
          const stateMatch =
            selectedStates.length === 0 ||
            selectedStates.some((state) => state.value === unit.state.code);

          const discomMatch =
            selectedDiscoms.length === 0 ||
            selectedDiscoms.some((discom) => discom.value === unit.discomCode);

          const meters = unit.meters.filter(
            (meter) =>
              selectedConsumers.length === 0 ||
              selectedConsumers.some((consumer) => consumer.value === meter._id)
          );

          const dateMatch = (() => {
            if (!selectedYear && !selectedMonth) return true;
            const unitDate = new Date(unit.date); // Replace with actual date field from your data
            const yearMatch = selectedYear
              ? unitDate.getFullYear() === selectedYear.getFullYear()
              : true;
            const monthMatch = selectedMonth
              ? unitDate.getMonth() === selectedMonth.getMonth()
              : true;
            return yearMatch && monthMatch;
          })();

          return stateMatch && discomMatch && dateMatch && meters.length > 0;
        }),
      }));
      console.log({ filtered });
      setFilteredData(filtered);
    }
  }, [
    selectedStates,
    selectedDiscoms,
    selectedConsumers,
    selectedYear,
    selectedMonth,
    data,
  ]);

  const fetchPowerConsumptionData = async (filtersData) => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/recommendation/saveEnergyList?pricetype=constantprice&&calculations_steps=0`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(filtersData),
        }
      );
      setTimeout(() => {
        setLoading(false);
      }, 500);

      const result = await response.json();

      if (result.statusCode === 200) {
        console.log({ tableData: result.data["results"] });
        setTableData(result.data["results"]);
        setTotalRecords(result.data["total"]);
        setTotalLocation(result.data["totalLocation"]);
        setTotalPages(Math.ceil(result.data["total"] / pageSize) || 1);
      }
    } catch (error) {
      console.error("Error fetching power consumption data:", error);
    }
  };

  const handlePageChange = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      setFilters({ ...filters, page: currentPage + 1 });
      setSearch(true);
    }
  };

  const handlePreviousPageChange = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setFilters({ ...filters, page: currentPage - 1 });
      setSearch(true);
    }
  };

  useEffect(() => {
    const totalPages = Math.ceil(totalRecords / parseInt(pageSize));

    // Ensure current page is within the total pages range
    if (currentPage > totalPages && totalRecords > 0) {
      setCurrentPage(1);
      setFilters({ ...filters, page: 1 });
      setSearch(true);
    }
  }, [tableData]);

  const handlepageSizeChange = (e) => {
    setPageSize(e.target.value);
    localStorage.setItem("pageSize", e.target.value);
    setFilters({ ...filters, pageSize: parseInt(e.target.value) });
    setSearch(true);
  };

  useEffect(() => {
    const updatedPowerConsumptionData = async () => {
      let filtersData = {};
      const { accessLevel, states, meters, units, discoms, userType } =
        await getUnitsandMeters();
      if (userType !== "admin") {
        if (accessLevel === "meter") {
          filtersData["meterId"] = meters;
        } else if (accessLevel === "unit") {
          filtersData["unitId"] = units;
        } else if (accessLevel === "discom") {
          filtersData["discomCode"] = discoms;
        } else {
          filtersData["stateCode"] = states;
        }
      }

      if (queryParamsLength === 0 && initialLoad) {
        filtersData["stateCode"] = ["IN-MH"];
        filtersData["startDate"] = "04/2023";
        filtersData["endDate"] = "03/2024";
      }

      const checkUpdateFilterOrnot = query.get("filter");
      console.log({ search });
      if (
        (orgID &&
          initialLoad &&
          (queryParamsLength === 0 || queryParamsLength === 1)) ||
        search
      ) {
        filtersData.organisationId = orgID;
        filtersData = { ...filters, ...filtersData };
        if (checkUpdateFilterOrnot && initialLoad) {
          filtersData =
            JSON.parse(localStorage.getItem("filterFromPaymentDetailsPage")) ||
            {};
          // updateLocalFilters();
        } else {
          localStorage.setItem(
            "filterFromPaymentDetailsPage",
            JSON.stringify(filtersData)
          );
        }

        fetchPowerConsumptionData(filtersData);
        setSearch(false);
        setInitialLoad(false);
      }
    };
    updatedPowerConsumptionData();
  }, [filters, orgID, queryParamsLength, search]);

  function formatAsINR(amount) {
    if (amount === null) {
      return 0;
    }
    const numericAmount =
      typeof amount === "string" ? parseFloat(amount) : amount;

    if (isNaN(numericAmount)) {
      return 0;
      // throw new Error("Invalid amount");
    }

    return numericAmount.toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
    });
  }

  useEffect(() => {
    const updateLocalFilter = query.get("filter");
    const filterData = JSON.parse(
      localStorage.getItem("filterFromPaymentDetailsPage")
    );
    if (
      updateLocalFilter &&
      filterData.discomCode &&
      selectedStates.length > 0
    ) {
      const discoms = filterData.discomCode.map((discom) => {
        return { label: discom, value: discom };
      });
      setSelectedDiscoms(discoms);
    }
  }, [selectedStates]);

  useEffect(() => {
    const updateLocalFilter = query.get("filter");
    const filterData = JSON.parse(
      localStorage.getItem("filterFromPaymentDetailsPage")
    );
    if (
      updateLocalFilter &&
      filterData.consumerNo &&
      selectedDiscoms.length > 0
    ) {
      const consumers = filterData.consumerNo.map((consumer) => {
        return { label: consumer, value: consumer };
      });
      setSelectedConsumers(consumers);
    }
  }, [selectedDiscoms]);

  const resetFilters = () => {
    setSelectedDiscoms([]);
    setSelectedConsumers([]);
    setSelectedRecommedationYear("FY2023-24");
    setCurrentPage(1);
    setPageSize(5);
    setFilters({ ...initialPayload });
    setSearch(true);
    setRecommedationYears(constantUtils.getLastFiveFinancialYears());
    navigate(location.pathname, { replace: true });
  };

  const getCurrentDateTime = () => {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    return `${year}${month}${day}_${hours}${minutes}${seconds}`;
  };
  const handleDownload = async () => {
    try {
      let updatedFilters = { ...filters };

      const {
        userType,
        meters,
        accessLevel,
        discoms,
        units,
        states,
        organisationId,
      } = await getUnitsandMeters();

      if (userType === "admin") {
        updatedFilters["organisationId"] = organisationId;
      } else {
        console.log({ accessLevel });
        updatedFilters["organisationId"] = organisationId;
        if (accessLevel === "meter") {
          updatedFilters["meterId"] = meters;
        } else if (accessLevel === "unit") {
          updatedFilters["unitId"] = units;
        } else if (accessLevel === "discom") {
          updatedFilters["discomCode"] = discoms;
        } else {
          updatedFilters["stateCode"] = states;
        }
      }
      console.log({ updatedFilters });

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/recommendation/downloadReport?pricetype=constantprice&&calculations_steps=1`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Ensure headers indicate a JSON request body
          },
          body: JSON.stringify(updatedFilters),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to download file");
      }
      const contentDisposition = response.headers.get("content-disposition");
      let filename = `${getCurrentDateTime()}.csv`;
      if (
        contentDisposition &&
        contentDisposition.indexOf("attachment") !== -1
      ) {
        const match = contentDisposition.match(/filename="(.+)"/);
        if (match.length === 2) filename = match[1];
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Download failed:", error);
    }
  };

  function getConsumerNosByMeterIds(data, meterIds) {
    const consumerNos = [];

    if (!data?.factories) {
      return consumerNos; // Return empty array if factories data is not available
    }

    for (const meterId of meterIds) {
      let found = false;

      for (const factory of data.factories) {
        for (const unit of factory.units) {
          for (const meter of unit.meters) {
            if (meter._id === meterId) {
              consumerNos.push(meter.consumerNo); // Add the consumer number to the array
              found = true;
              break;
            }
          }
          if (found) break;
        }
        if (found) break;
      }

      // If no matching meter ID is found, add null for that meter ID
      if (!found) {
        consumerNos.push(null);
      }
    }

    return consumerNos; // Return the array of consumer numbers
  }

  const convertToStartDate = (dateString) => {
    const [month, year] = dateString.split("/");
    // Create a moment object for the first day of the specified month (UTC)
    const firstDayOfMonth = moment
      .utc(`${year}-${month}-01`, "YYYY-MM-DD")
      .startOf("day");
    return firstDayOfMonth.toDate();
  };

  const convertToEndDate = (dateString) => {
    const [month, year] = dateString.split("/");
    // Convert to the last day of the month using momentP
    const date = moment
      .utc(`${year}-${month}-01`, "YYYY-MM-DD")
      .endOf("month")
      .startOf("day")
      .toDate();
    return date;
  };

  useEffect(() => {
    if (data) {
      const stateFromQuery = query.get("state");
      const consumerFromQuery = query.get("consumers");
      const startDateFromQuery = query.get("startDate");
      const endDateFromQuery = query.get("endDate");
      let states;
      const formattedStartDate = startDateFromQuery
        ? convertToStartDate(startDateFromQuery)
        : null;

      const formattedEndDate = endDateFromQuery
        ? convertToEndDate(endDateFromQuery)
        : null;
      if (stateFromQuery && data) {
        const sanitizedStateFromQuery = stateFromQuery
          .replace(/[\[\]']/g, "") // Remove square brackets and single quotes
          .split(","); // Split the string by commas
        if (sanitizedStateFromQuery.length > 0) {
          states = sanitizedStateFromQuery.map((state) => {
            return { label: state, value: state };
          });
          setSelectedStates(states);
        }
      }
      if (consumerFromQuery && data && states.length > 0) {
        // select all discom of selected states
        const discomCodes = [];
        const sampleArray = states.map((state) => state.value);
        console.log({ data });
        data.factories.forEach((factory) => {
          factory.units.forEach((unit) => {
            if (sampleArray.includes(unit.stateCode)) {
              discomCodes.push(unit.discomCode);
            }
          });
        });
        const uniqueDiscomCodes = [...new Set(discomCodes)];
        console.log({ uniqueDiscomCodes });
        const discoms = uniqueDiscomCodes.map((discom) => {
          return { label: discom, value: discom };
        });
        setSelectedDiscoms(discoms);
        const sanitizedConsumerFromQuery = consumerFromQuery
          .replace(/[\[\]']/g, "") // Remove square brackets and single quotes
          .split(","); // Split the string by commas

        const consumerNos = getConsumerNosByMeterIds(
          data,
          sanitizedConsumerFromQuery
        );

        const consumers = consumerNos.map((consumer) => {
          return { label: consumer, value: consumer };
        });
        setSelectedConsumers(consumers);
      }

      if (formattedStartDate && formattedEndDate) {
        console.log({ formattedStartDate, formattedEndDate });
        const year = formattedStartDate.getFullYear().toString();
        setSelectedRecommedationYear(
          year === "2023" ? "FY2023-24" : year === "2024" ? "FY2024-25" : "FY2025-26"
        );        
        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);
      }
      //   setTimeout(() => {
      //     // if (
      //     //   (stateFromQuery && !consumerFromQuery && selectedStates.length > 0) ||
      //     //   (consumerFromQuery && selectedConsumers.length > 0)
      //     // ) {
      //       updateFilterData();
      //     // }
      //   }, 2000);
    }
  }, [data]);

  useEffect(() => {
    const stateFromQuery = query.get("state");
    const consumerFromQuery = query.get("consumers");
    if (
      stateFromQuery &&
      !consumerFromQuery &&
      selectedStates.length > 0 &&
      initialLoad
    ) {
      updateFilterData();
    }
  }, [selectedStates]);

  useEffect(() => {
    const consumerFromQuery = query.get("consumers");
    if (consumerFromQuery && selectedConsumers.length > 0 && initialLoad) {
      updateFilterData();
    }
  }, [selectedConsumers]);

  const renderSortArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? (
        <FontAwesomeIcon icon={faArrowUp} />
      ) : (
        <FontAwesomeIcon icon={faArrowDown} />
      );
    }
    return null;
  };

  const sortData = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    console.log({ key, direction });
    console.log({ filters });
    setFilters({ ...filters, sortBy: key, sortOrder: direction });
    // Update sorting configuration
    setSortConfig({ key, direction });
    setSearch(true);
  };

  const handleStartDateEndDate = (yearString) => {
    const match = yearString.match(/^FY(\d{4})-(\d{2})$/);
    if (match) {
      const startYear = parseInt(match[1], 10);
      const endYear = startYear + 1;

      setStartDate(new Date(`${startYear}-04-01`));
      setEndDate(new Date(`${endYear}-03-31`));
    } else {
      console.error("Invalid financial year format");
    }
  };

  useEffect(() => {
    if (queryParamsLength === 0 && stateOptions.length > 0) {
      setSelectedRecommedationYear("FY2023-24");
      setStartDate(new Date("2023-04-01"));
      setEndDate(new Date("2024-03-31"));
      setSelectedStates(stateOptions);
    }
  }, [stateOptions]);

  useEffect(() => {
    if (futureRecommendation) {
         const year = constantUtils.getNextFinancialYear();
         let startDate ,endDate ;
         const match = year.match(/^FY(\d{4})-(\d{2})$/);
         if (match) {
           const startYear = parseInt(match[1], 10);
           const endYear = startYear + 1;
     
           startDate = new Date(`${startYear}-04-01`);
           endDate = new Date(`${endYear}-03-31`);
         } else {
           console.error("Invalid financial year format");
         }

      const orgId = orgID;
      let dateFilters = {};
      if (startDate !== "" && endDate !== "") {
        dateFilters = {
          startDate: `${String(startDate.getMonth() + 1).padStart(
            2,
            "0"
          )}/${startDate.getFullYear()}`,
          endDate: `${String(endDate.getMonth() + 1).padStart(
            2,
            "0"
          )}/${endDate.getFullYear()}`,
        };
      }
      const stateCode = selectedStates.map((state) => state.value);
      const url = `/recommendation-list?org=${orgId}&startDate=${dateFilters.startDate}&endDate=${dateFilters.endDate}&state=${stateCode}&recommendation=future`;
      window.open(url, "_blank");
    }
    setFutureRecommendation(false);
  }, [futureRecommendation]);

  const handleFutureRecommendation = () => {
    setFutureRecommendation(true);
  };

  return (
    <div className="max-w-[1920px] mt-[100px] m-auto flex">
      <div className="hidden sm:block">
        <Sidebar />
      </div>
      <div
        className={`${
          isSidebarOpen ? "sm:ml-[300px]" : "sm:pl-[7%]"
        } w-full flex flex-col justify-left items-center transition-width duration-300`}
      >
        <div className="w-full  mr-[2%] sm:shadow-[2px_2px_2px_-1px_rgb(0,0,0,0.1),-1px_-1px_4px_2px_rgb(0,0,0,0.1)]">
          <div className="flex  justify-between items-center">
            <h2 className="text-2xl font-medium p-4">
              {isFutureRecommendation
                ? "Future Recommendation"
                : "Recommendations List"}
            </h2>
            <div className="flex  justify-end gap-4  m-2 ">
              {!isFutureRecommendation && (
                <div
                  onClick={handleFutureRecommendation}
                  className="flex gap-4 px-2 py-2 bg-green-700 cursor-pointer rounded-lg text-white"
                  data-tooltip-id="future-tooltip"
                >
                  Future Recommendations
                </div>
              )}

              <div
                data-tooltip-id="my-tooltip"
                onClick={() => navigate("/")}
                className="cursor-pointer bg-green-700 rounded-lg text-white px-4 py-2"
              >
                <FontAwesomeIcon data-tip="Click to download" icon={faHome} />
              </div>
              <ReactTooltip
                id="back-tooltip"
                place="bottom"
                effect="solid"
                style={{
                  backgroundColor: "#90EE90",
                  color: "#222",
                  zIndex: "41",
                }}
              >
                <div>
                  <h3>Go Back to Previous Page</h3>
                </div>
              </ReactTooltip>
              <ReactTooltip
                id="future-tooltip"
                place="bottom"
                effect="solid"
                style={{
                  backgroundColor: "#90EE90",
                  color: "#222",
                  zIndex: "41",
                }}
              >
                <div>
                  <h3>
                    View Future Recommendation for{" "}
                    {constantUtils.getNextFinancialYear()}
                  </h3>
                </div>
              </ReactTooltip>
              <ReactTooltip
                style={{
                  backgroundColor: "#90EE90",
                  color: "#222",
                  zIndex: "41",
                }}
                place={"bottom"}
                id="my-tooltip"
              >
                <div>
                  <h3>Go To Dashboard</h3>
                </div>
              </ReactTooltip>
              <ReactTooltip
                style={{
                  backgroundColor: "#90EE90",
                  color: "#222",
                  zIndex: "41",
                }}
                place={"bottom"}
                id="download"
              >
                <div>
                  <h3>Download Report</h3>
                </div>
              </ReactTooltip>
              <div
                data-tooltip-id="download"
                onClick={handleDownload}
                className="cursor-pointer bg-green-700 rounded-lg text-white px-4 py-2"
              >
                <FontAwesomeIcon size="xl" icon={faDownload} />
              </div>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row sm:items-center w-full">
            <div className="flex flex-col sm:flex-row  gap-4 w-full px-5">
              <div className="dashboard py-4 sm:py-0 flex flex-col justify-center mx-4 sm:mx-2">
                <select
                  className={`p-2 rounded-lg border border-gray-300 ${
                    selectedRecommedationYear !== ""
                      ? "text-black"
                      : "text-gray-400"
                  }`}
                  value={selectedRecommedationYear}
                  onChange={(e) => {
                    setSelectedRecommedationYear(e.target.value);
                    handleStartDateEndDate(e.target.value);
                  }}
                >
                  <option className="text-gray-400"> Select Year</option>
                  {recommedationYears?.map((fyyear) => (
                    <option key={fyyear} value={fyyear}>
                      {fyyear}
                    </option>
                  ))}
                </select>
              </div>
              <div className="sm:w-[15%] z-[40]">
                <MultiSelect
                  options={stateOptions}
                  value={selectedStates}
                  overrideStrings={{
                    selectSomeItems: "Select state code",
                    allItemsAreSelected: "All States selected",
                  }}
                  onChange={setSelectedStates}
                  labelledBy="Select States"
                />
              </div>
              <div className="sm:w-[15%] z-[39]">
                <MultiSelect
                  options={discomOptions}
                  value={selectedDiscoms}
                  overrideStrings={{
                    selectSomeItems: "Select Discom code",
                    allItemsAreSelected: "All Discoms selected",
                  }}
                  onChange={setSelectedDiscoms}
                  labelledBy="Select Discoms"
                  isDisabled={selectedStates.length === 0}
                />
              </div>
              <div className="sm:max-w-[15%]  z-[38]">
                <MultiSelect
                  options={consumerOptions}
                  value={selectedConsumers}
                  className="sm:max-w-[180px] sm:min-w-[180px]"
                  overrideStrings={{
                    selectSomeItems: "Select Consumer No",
                    allItemsAreSelected: "All Consumers selected",
                  }}
                  onChange={setSelectedConsumers}
                  labelledBy="Select Consumers"
                  isDisabled={selectedDiscoms.length === 0}
                />
              </div>

              <div className="sm:max-w-[15%]  z-[38]">
                <input
                  //   options={locations}
                  value={selectedLocations}
                  placeholder="Enter Location"
                  className="sm:max-w-[180px] border border-gray-300 rounded-lg px-4 py-[7px] sm:min-w-[180px]"
                  overrideStrings={{
                    selectSomeItems: "Select Locations",
                    allItemsAreSelected: "All Locations selected",
                  }}
                  onChange={(e) => setSelectedLocations(e.target.value)}
                  //   labelledBy="Select Location"
                  //   isDisabled={selectedLocations.length === 0}
                />
              </div>
            </div>

            <div className="flex p-5 gap-4 sm:pb-5 mx-2 items-start sm:items-center">
              <div
                onClick={updateFilterData}
                className="flex  gap-4 px-5 py-[6px] bg-green-700 cursor-pointer  rounded-lg text-white"
              >
                Filter
              </div>
              <div
                onClick={resetFilters}
                className="flex  gap-4 px-5 py-1 rounded-lg border-2 cursor-pointer border-green-700 text-black"
              >
                Reset
              </div>
            </div>
          </div>
          {!loading && tableData?.length > 0 ? (
            <div className="relative w-full overflow-y-auto shadow-md sm:rounded-lg">
              <table className="w-full   pt-2 text-sm text-center rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="bg-green-700 w-full  text-white top-0">
                  <tr>
                    <th className="px-2 py-4 sm:w-[8%]  font-medium">State</th>
                    <th className="px-2 py-4 sm:w-[8%]    font-medium">
                      Discom
                    </th>
                    <th className="px-2 py-4  sm:w-[8%]   font-medium">
                      Consumer No
                    </th>
                    <th className="px-2 py-4 cursor-pointer  sm:w-[8%]   font-medium">
                      Circle
                    </th>
                    <th className="px-2 py-4 cursor-pointer  max-w-[80px]   font-medium">
                      Location
                    </th>
                    <th
                      onClick={() => sortData("totalSavingsFromGroupCaptive")}
                      className="px-2 py-4  cursor-pointer sm:w-[8%]  font-medium"
                    >
                      GC Savings
                      {renderSortArrow("totalSavingsFromGroupCaptive")}
                    </th>
                    <th
                      onClick={() => sortData("totalCapacityGC")}
                      className="px-2 py-4  cursor-pointer  sm:w-[9%]   font-medium"
                    >
                      Capacity GC{renderSortArrow("totalCapacityGC")}
                    </th>
                    <th
                      onClick={() => sortData("totalSavingsFromRTS")}
                      className="px-2 py-4   cursor-pointer   sm:w-[9%]   font-medium"
                    >
                      RTS Savings{renderSortArrow("totalSavingsFromRTS")}
                    </th>
                    <th
                      onClick={() => sortData("totalCapacityRTS")}
                      className="px-2 py-4 cursor-pointer  sm:w-[9%]  font-medium"
                    >
                      Capacity RTS {renderSortArrow("totalCapacityRTS")}
                    </th>
                    <th className="px-2 py-4   sm:w-[7%]  cursor-pointer font-medium">
                      Action
                    </th>
                  </tr>
                </thead>
              </table>
              <div
                className={` sm:overflow-auto upload-bills`}
                style={{
                  maxHeight: width > 1080 ? `${height - 400}px` : ``,
                }}
              >
                <table className="w-full  pt-2 text-sm text-center rtl:text-right text-gray-500 dark:text-gray-400">
                  <tbody>
                    {tableData.map((row, index) => (
                      <tr
                        key={index}
                        className={`border-b ${
                          (index + 1) % 2 === 0 ? "bg-gray-50" : "bg-gray-100"
                        }`}
                      >
                        <td className="px-2 py-4  w-[8%]  ">{row.stateCode}</td>
                        <td className="px-2 py-4  w-[8%]  ">
                          {row.discomCode}
                        </td>
                        <td className="px-2 py-4  w-[8%]  ">
                          {row.consumerNo}
                        </td>
                        <td className="px-2 py-4  w-[8%]  ">
                          {row.circle || ""}
                        </td>
                        <td className="px-2 py-4 overflow-hidden   max-w-[80px]  ">
                          {row.location || ""}
                        </td>
                        <td className="px-2 py-4  w-[8%]  ">
                          {formatAsINR(
                            Math.ceil(row.totalSavingsFromGroupCaptive)
                          ) || "0"}
                        </td>
                        <td className="px-2 py-4  w-[8%]  ">
                          <span>{row.totalCapacityGC || "0"} &ensp; MWp</span>
                        </td>
                        <td className="px-2 py-4  w-[8%]  ">
                          {formatAsINR(Math.ceil(row.totalSavingsFromRTS)) ||
                            "0"}
                        </td>
                        <td className="px-2 py-4  w-[8%]  ">
                          <span> {row.totalCapacityRTS || "0"}&ensp; MWp</span>
                        </td>
                        <td
                          //   onClick={() => setIsOpen(!isOpen)}
                          onClick={() => {
                            getmeterIdFromConsumerNo(row.consumerNo);
                            setIsOpen(!isOpen);
                          }}
                          className="px-2 py-4 text-blue-700 underline cursor-pointer  w-[8%]  "
                        >
                          View More
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex justify-between items-center p-4">
                <div className="flex items-center gap-2 ">
                  <label htmlFor="pageSize" className="mr-2">
                    Page Size:
                  </label>
                  <select
                    id="pageSize"
                    value={pageSize}
                    className="border rounded px-2 py-1"
                    onChange={handlepageSizeChange}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div className="flex items-center gap-2 mt-4">
                  <div className="hidden sm:block">
                    <strong>Total Consumers:</strong> {totalRecords}
                  </div>
                  <div className="hidden sm:block mx-2">
                    <strong> Total Locations:</strong> {totalLocation}
                  </div>

                  <div
                    onClick={() => handlePreviousPageChange()}
                    className={`bg-gray-300 px-2 py-1 rounded hover:bg-gray-400 ${
                      currentPage === 1
                        ? "opacity-50 cursor-not-allowed"
                        : "cursor-pointer"
                    }`}
                  >
                    Previous
                  </div>
                  <span>
                    Page {currentPage} of {totalPages}
                  </span>
                  <div
                    onClick={() => handlePageChange()}
                    className={`bg-gray-300 px-2 py-1 rounded hover:bg-gray-400 ${
                      1 === totalPages
                        ? "opacity-50 cursor-not-allowed"
                        : "cursor-pointer"
                    }`}
                  >
                    Next
                  </div>
                </div>
              </div>
            </div>
          ) : loading ? (
            <div className="min-h-[50vh] flex justify-center items-center">
              <LoaderComponent />
            </div>
          ) : (
            <div className="min-h-[50vh] flex justify-center items-center">
              <span>Recommendation Not Processed Please Contact Admin.</span>
            </div>
          )}
        </div>
      </div>
      <RecommendationNew
        selectedMeterId={selectedMeterId}
        isClosing={isClosing}
        closePopover={closePopover}
        startDatefromParent={startDate}
        endDatefromParent={endDate}
        isOpen={isOpen}
        height={height}
        setIsOpen={setIsOpen}
      />
    </div>
  );
};

export default RecommendationList;
